import React from "react"

import Layout from "../components/layout"
import Article from "../components/article"
import SEO from "../components/seo"
import Bio from "../components/Bio"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Article>
      <Bio />
    </Article>
  </Layout>
)

export default AboutPage
